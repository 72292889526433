
const classes = {
    active: 'active'
};

/**
 * Accordion function
*/
function accordionHandle() {
    const accordionBtnElements = document.querySelectorAll('.accordion'); // Group of all accordions
    const collapseAllAccordionElement = document.querySelector('.accordion-collapse'); // Container that allow the collapse feature in the accordion


    accordionBtnElements.forEach(accordion => {
        accordion.addEventListener('click', (el) => {

            //Check if Parent container has the class "accordion-collapse"
            if (collapseAllAccordionElement !== null) {
                collapseAll(el.target);
            }

            // collapseOpenByDefault();
            el.target.classList.toggle(classes.active);

            // Set the Max Height of the next element
            let accordionPanel = el.target.nextElementSibling;

            if (el.target.classList.contains(classes.active)) {
                accordionPanel.style.maxHeight = accordionPanel.scrollHeight + 'px';
            } else {
                accordionPanel.style.maxHeight = null;
            }
        });
    });
}

/**
 * Function that handles only one open accordion at the time.
 * You need to set a parent container that contains a class="accordion-collapse"
 *  @param {Object} el - clicked element
 */
function collapseAll (el) {
    const accordionBtnElements = document.querySelectorAll('.accordion');

    accordionBtnElements.forEach(accordion => {
        if (accordion !== el) {
            accordion.nextElementSibling.style.maxHeight = null;
            accordion.classList.remove(classes.active);
        }
    });
}

module.exports = {
    accordionHandle
};
