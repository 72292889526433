'use strict';

const elements = {
    $document: $(document),
    $page: $('.page')
};

const selectors = {
    countrySelect: '.js-locale-select'
};

/**
 * Handles country/locale selector
 */
function countrySelector() {
    elements.$document.on('change', selectors.countrySelect, function() {
        const $this = $(this);
        const $selectedOption = $this.find('option:selected');
        const url = $this.data('url');

        let localeCode = $selectedOption.data('locale');
        let localeCurrencyCode = $selectedOption.data('currencycode');

        // Data getting on a page level
        const action = elements.$page.data('action');
        const queryString = elements.$page.data('querystring');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                action: action
            },
            success: function (response) {
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            },
            error: function () {
                console.log('Something went wrong!');
            }
        });
    });
}
export default countrySelector;
