'use strict';

import $ from 'jquery';
import debounce from 'lodash.debounce';
import { processInclude } from './utils/helpers';
import handleSpinner from './components/spinner';
import handleNewsletterForm from './components/newsletterForm';
// import gtmEvents from './gtm/gtmEvents';
import header from './components/header';
import 'lazysizes';

// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = $;
/* Dom ready */
$(() => {
    handleSpinner();
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/modalMain'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./product/quickView'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/cookieConsent'));
    processInclude(require('./components/accordion'));
    // gtmEvents.init(dataLayer);
    // gtmEvents.onload(dataLayer);
    header.init();
    handleNewsletterForm();
});

// /* Window resize */
$(window).on('resize', debounce(() => {
    // reInit
}, 600));
