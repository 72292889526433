'use strict';

const elements = {
    openConsentWindow: '.js-open-consent-window',
    consentDialogueContent: '.js-cookie-consent-dialog-content',
    cookieDialogScreen: '.js-cookie-dialog-screen',
    closeCookieModal: '.js-close-cookie-bnt',
    cookieDialogWrapper: '#cookie-dialog-wrapper',
    cookieMarketing: '#cookie-select-marketing',
    cookiePersonalization: '#cookie-select-personalization',
    cookiePerformance: '#cookie-select-performance',
    cookieAll: '#cookie-select-all',
    unCheckedCheckbox: '.slide-checkbox[disabled!=disabled]'
};

const classes = {
    displayNone: 'display-none'
};

/**
 * Handles cookieModal
 */
function cookieConsentModal() {
    const {
        openConsentWindow, consentDialogueContent, cookieDialogScreen, closeCookieModal, cookieMarketing, cookiePersonalization, cookiePerformance, cookieAll, unCheckedCheckbox
    } = elements;
    const { displayNone } = classes;

    $(document).on('click', openConsentWindow, function (e) {
        e.preventDefault();
        $(unCheckedCheckbox).removeAttr('checked');
        $(consentDialogueContent).removeClass(displayNone);
        $(cookieDialogScreen).addClass(displayNone);
    });

    $(document).on('click', closeCookieModal, function () {
        $(unCheckedCheckbox).attr('checked','checked');
        $(consentDialogueContent).addClass(displayNone);
        $(cookieDialogScreen).removeClass(displayNone);
    });

    // Logic to handle checkboxes. ex: select all
    $(document).on('click', "input[type='checkbox']", (e) => {
        var checkboxId = e.target.id;
        if (e.target.checked && checkboxId === 'cookie-select-all') {
            $(cookieMarketing).prop('checked', true);
            $(cookiePersonalization).prop('checked', true);
            $(cookiePerformance).prop('checked', true);
        } else if (!e.target.checked && checkboxId !== 'cookie-select-all') {
            $(cookieAll).prop('checked', false);
        }
    });
}

module.exports = {
    cookieConsentModal
};
