'use strict';

const errorHelpers = require('../utils/errorHelper');
const recommendationCarousel = require('../components/recommendationCarousel');

window.subscriptionState = {
    mode: ''
};

const cache = {
    $subscriptionForm: $('.js-subscription-form'),
    $subscriptionOptions: $('.js-subscription-option'),
    $onetimeOption: $('.js-subscription-option-onetime'),
    $subscriptionOption: $('.js-subscription-option-subscription'),
    $subscriptionPeriodicity: $('.js-subscription-periodicity'),
    $checkoutBtn: $('.js-checkout-btn'),
    $modeInput: $('.js-mode-input'),
    $body: $('body')
};

const classes = {
    active: 'active',
    disabled: 'disabled'
};

const SUBSCRIPTION_MODES = {
    ONE_TIME: 'onetime',
    SUBSCRIPTION: 'subscription'
};

/**
 * Inits event listeners
 */
function init() {
    const $subscriptionOptions = $('.js-subscription-option');
    const $subscriptionPeriodicity = $('.js-subscription-periodicity');
    $subscriptionOptions.on('click', onOptionSelect);
    $subscriptionPeriodicity.on('change', onPeriodicityChange);
    window.subscriptionState.mode = $('.js-subscription-option.active').data('mode');

    recommendationCarousel.init();
}

/**
 * Handles option selection
 * @param {Object} event - jQuery event
 */
function onOptionSelect(event) {
    if ($(event.target).is('.js-subscription-forbidden-message a')) {
        // ignore error message link clicks
        return;
    }

    const $selectedOption = $(event.currentTarget);
    const selectedMode = $selectedOption.data('mode');

    if (selectedMode === window.subscriptionState.mode) {
        // Already selected
        return;
    }

    updateSubscriptionState(selectedMode);
    submitSubscriptionData();
}

/**
 * Updates UI up to subscription mode
 * @param {string} subscriptionMode - subscription mode
 */
function updateSubscriptionState(subscriptionMode) {
    if (subscriptionMode === window.subscriptionState.mode) {
        // Prevent unnecessary updates
        return;
    }

    window.subscriptionState.mode = subscriptionMode;

    cache.$subscriptionOptions.removeClass(classes.active);
    cache.$modeInput.val(window.subscriptionState.mode);

    if (window.subscriptionState.mode === SUBSCRIPTION_MODES.SUBSCRIPTION) {
        cache.$subscriptionOption.addClass(classes.active);
        cache.$subscriptionPeriodicity.removeAttr('disabled');
    } else {
        cache.$onetimeOption.addClass(classes.active);
        cache.$subscriptionPeriodicity.attr('disabled', 'disabled');
    }
}

/**
 * Handles periodicity change
 */
function onPeriodicityChange() {
    submitSubscriptionData();
}

/**
 * Submits subscription data
 */
function submitSubscriptionData() {
    const url = cache.$subscriptionForm.attr('action');

    cache.$checkoutBtn.addClass(classes.disabled);

    errorHelpers.clearErrorNotification();

    $.ajax({
        url: url,
        method: 'POST',
        data: cache.$subscriptionForm.serialize(),
        success: (response) => {
            if (response.error) {
                errorHelpers.createErrorNotification(response.errorMsg);
            } else {
                cache.$body.trigger('cart:updated', response);
            }

            cache.$checkoutBtn.removeClass(classes.disabled);
        },
        error: (error) => {
            if (error.responseJSON && error.responseJSON.errorMsg) {
                errorHelpers.createErrorNotification(error.responseJSON.errorMsg);
            }

            cache.$checkoutBtn.removeClass(classes.disabled);
        }
    });
}

module.exports = {
    init,
    updateSubscriptionState
};
