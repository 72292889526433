/**
 * Handle Active Campaign form events
 * This complete code was taken from Active Campaign "Full Embed" forms integration option
 * Then it was changed to addapt it to our needs. Both versions are documented on Confluence
 */
function handleNewsletterForm() {
    window.cfields = { 37: "opt_in" };
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Load Script Function to load external scripts
    window._load_script = function (url, callback) {
        var head = document.querySelector("head");
        var script = document.createElement("script");
        var r = false;

        script.type = "text/javascript";
        script.charset = "utf-8";
        script.src = url;
        if (callback) {
            script.onload = function () {
                if (
                    !r &&
                    (!this.readyState || this.readyState == "complete")
                ) {
                    r = true;
                    callback();
                }
            };
            script.onreadystatechange = function () {
                if (
                    !r &&
                    (!this.readyState || this.readyState == "complete")
                ) {
                    r = true;
                    callback();
                }
            };
        }
        head.appendChild(script);
    };

    // Thank you Message after submit the form
    window._show_thank_you = function (id, message, trackcmpUrl, email) {
        var parentContainer = $(".js-newsletter__signup, .js-footer-form-wrapper");

        if (parentContainer.length) {
            parentContainer.addClass("was-submitted");
        }

        const vgoAlias =
            typeof visitorGlobalObjectAlias === "undefined"
                ? "vgo"
                : visitorGlobalObjectAlias;
        var visitorObject = window[vgoAlias];

        if (email && typeof visitorObject !== "undefined") {
            visitorObject("setEmail", email);
            visitorObject("update");
        } else if (typeof trackcmpUrl != "undefined" && trackcmpUrl) {
            _load_script(trackcmpUrl); // Site tracking URL to use after inline form submission.
        }

        if (typeof window._form_callback !== "undefined")
            window._form_callback(id);
    };

    var formEvents = (function () {
        if (window.location.search.search("excludeform") !== -1) return false;

        var addEvent = function (element, event, func) {
            if (element.addEventListener) {
                element.addEventListener(event, func);
            } else {
                var oldFunc = element["on" + event];
                element["on" + event] = function () {
                    oldFunc.apply(this, arguments);
                    func.apply(this, arguments);
                };
            }
        };

        var _removed = false;
        var footerFormIdInput = document.getElementById("_footer_form_id_");
        var signupFormIdInput = document.getElementById("_signup_form_id_");
        var formsToSubmit = [];

        if (footerFormIdInput) {
            formsToSubmit.push(
                document.getElementById(
                    "_form_" + footerFormIdInput.value + "_"
                )
            );
        }
        if (signupFormIdInput) {
            formsToSubmit.push(
                document.getElementById(
                    "_form_" + signupFormIdInput.value + "_"
                )
            );
        }

        var allInputs = [];
        for (var f = 0; f < formsToSubmit.length; f++) {
            var allInputsNodeList = formsToSubmit[f].querySelectorAll(
                "input, select, textarea"
            );
            var allInputsArray = Array.prototype.slice.call(allInputsNodeList);
            allInputs = allInputs.concat(allInputsArray);
        }

        var getUrlParam = function (name) {
            var regexStr = "[?&]" + name + "=([^&#]*)";
            var results = new RegExp(regexStr, "i").exec(window.location.href);
            return results != undefined
                ? decodeURIComponent(results[1])
                : false;
        };

        // Normal Signup Additional validation
        var validateForm = function (e) {

            $(".signup-form__btn").on("click", function () {
                var formIsValid = true; // Flag to track form validation status

                // Validate text input fields and email input
                $(this)
                    .parents("form")
                    .find('input[type="text"]')
                    .each(function () {
                        // Check if the field is required and empty
                        if ($(this).prop("required") && !$(this).val().trim()) {
                            $(this).addClass("is-invalid"); // Add error class to the field
                            var errorMessage =
                                $(this).data("validate-required");
                            // Display custom error message or default message
                            $(this)
                                .parents(".form__group")
                                .find(".invalid-feedback")
                                .text(
                                    errorMessage
                                        ? errorMessage
                                        : "This field is required."
                                );
                            formIsValid = false; // Set form validation status to false
                        }

                        // Check email format if it's provided and field is not empty
                        if (
                            $(this).attr("id") === "email" &&
                            $(this).val().trim()
                        ) {
                            if (!emailRegex.test($(this).val().trim())) {
                                $(this).addClass("is-invalid"); // Add error class to the field
                                var formatErrorMessage =
                                    $(this).data("validate-format"); // Get custom error message from data attribute

                                // Display custom error message or default message
                                $(this)
                                    .parents(".form__group")
                                    .find(".invalid-feedback")
                                    .text(
                                        formatErrorMessage
                                            ? formatErrorMessage
                                            : "Please enter a valid email address."
                                    );
                                formIsValid = false; // Set form validation status to false
                            }
                        }
                    });

                // Validate checkbox
                if (
                    $(this)
                        .parents("form")
                        .find("#field_37Yes")
                        .prop("required") &&
                    !$(this).parents("form").find("#field_37Yes").is(":checked")
                ) {
                    $(this)
                        .parents("form")
                        .find("#field_37Yes")
                        .addClass("is-invalid"); // Add error class to the checkbox
                    var checkboxErrorMessage = $(this)
                        .parents("form")
                        .find("#field_37Yes")
                        .data("validate-required"); // Get custom error message from data attribute
                    // Display custom error message or default message
                    $(this)
                        .parents("form")
                        .find("#field_37Yes")
                        .parents(".form__group")
                        .find(".invalid-feedback")
                        .text(
                            checkboxErrorMessage
                                ? checkboxErrorMessage
                                : "You must agree to receive communications."
                        );
                    formIsValid = false; // Set form validation status to false
                }

                // Prevent form submission if there are validation errors
                if (!formIsValid) {
                    return false; // Prevent form submission
                }
            });
        };

        // Footer Additional validation
        var validateFormFooter = function (e) {
            $(".footer-form__btn").on("click", function (e) {
                var formIsValid = true; // Flag to track form validation status

                // Validate text input fields and email input
                $(this)
                    .parents("._form-content")
                    .find('input[type="text"]')
                    .each(function () {
                        // Check if the field is required and empty
                        if ($(this).prop("required") && !$(this).val().trim()) {
                            $(this).addClass("is-invalid"); // Add error class to the field
                            var errorMessage =
                                $(this).data("validate-required");
                            // Display custom error message or default message
                            $(this)
                                .siblings(".invalid-feedback")
                                .text(
                                    errorMessage
                                        ? errorMessage
                                        : "This field is required."
                                );
                            formIsValid = false; // Set form validation status to false
                        }

                        // Check email format if it's provided and field is not empty
                        if (
                            $(this).attr("id") === "email" &&
                            $(this).val().trim()
                        ) {
                            if (!emailRegex.test($(this).val().trim())) {
                                $(this).addClass("is-invalid"); // Add error class to the field
                                var formatErrorMessage =
                                    $(this).data("validate-format"); // Get custom error message from data attribute
                                // Display custom error message or default message
                                $(this)
                                    .siblings(".invalid-feedback")
                                    .text(
                                        formatErrorMessage
                                            ? formatErrorMessage
                                            : "Please enter a valid email address."
                                    );
                                formIsValid = false; // Set form validation status to false
                            }
                        }
                    });

                // Validate checkbox
                var footerCheckbox = $(this)
                    .parents("._form-content")
                    .find("#field_37Yes");
                if (
                    $(footerCheckbox).prop("required") &&
                    !$(footerCheckbox).is(":checked")
                ) {
                    $(footerCheckbox).addClass("is-invalid"); // Add error class to the checkbox
                    var checkboxErrorMessage =
                        $(footerCheckbox).data("validate-required"); // Get custom error message from data attribute
                    // Display custom error message or default message
                    $(footerCheckbox)
                        .siblings(".invalid-feedback")
                        .text(
                            checkboxErrorMessage
                                ? checkboxErrorMessage
                                : "You must agree to receive communications."
                        );
                    formIsValid = false; // Set form validation status to false
                }

                // Prevent form submission if there are validation errors
                if (!formIsValid) {
                    e.preventDefault(); // Prevent form submission
                }
            });
        };

        // Execution of Serialize
        window._old_serialize = null;
        if (typeof serialize !== "undefined")
            window._old_serialize = window.serialize;
        _load_script(
            "//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
            function () {
                window._form_serialize = window.serialize;
                if (window._old_serialize)
                    window.serialize = window._old_serialize;
            }
        );

        // Execution of Form Validation
        validateForm();

        // Execution of Form Footer Validation
        validateFormFooter();

        // Submit From Function
        var submitFooterForm = function (e) {
            e.preventDefault();

            // use this trick to get the submit button & disable it using plain javascript
            e.originalEvent.submitter.disabled = true;
            var serialized = $(this).serialize().replace(/%0A/g, "\\n");
            var err = $(this).find("._form_error");
            if (err.length) {
                err.parent().remove();
            }
            _load_script(
                "https://skandinavisk.activehosted.com/proc.php?" +
                    serialized +
                    "&jsonp=true"
            );
            return false;
        };

        // Submit Function
        var submitNewsletterForm = function (e) {
            e.preventDefault();

            var serialized = $(this).serialize().replace(/%0A/g, "\\n");

            _load_script(
                "https://skandinavisk.activehosted.com/proc.php?" + serialized + "&jsonp=true"
            );
            return false;
        };

        // Attach submit event for Footer Form
        $(".js-newsletter-form-footer").on("submit", submitFooterForm);

        // Attach submit event for Newsletter Form
        $(".js-newsletter-form").on("submit", submitNewsletterForm);
    })();

}

export default handleNewsletterForm;
