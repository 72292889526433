'use strict';

var { appendToUrl } = require('../utils/urlHelpers');
var { createErrorNotification, clearErrorNotification } = require('../utils/errorHelper');
var { updateSubscriptionState } = require('../subscription/cart');
var { updateBonusProducts } = require('./bonusProducts');

var elements = {
    $document: $(document),
    $body: $('body'),
    $cartPage: $('.js-cart-page'),
    $miniCartQuantity: $('.js-minicart-quantity'),
    $miniCartLink: $('.js-minicart-link'),
    $numberOfItems: $('.js-number-of-items'),
    $checkoutBtn: $('.js-checkout-btn'),
    $couponMissingErr: $('.js-coupon-missing-error'),
    $couponErrMsg: $('.js-coupon-error-message'),
    $errorContainer: $('.js-alert'),
    $couponsAndPromos: $('.js-coupons-and-promos'),
    $grandTotal: $('.js-grand-total'),
    $orderDiscount: $('.js-order-discount'),
    $orderDiscountTotal: $('.js-order-discount-total'),
    $subscriptionDetails: $('.js-subscription-details'),
    $subscriptionForbiddenMessage: $('.js-subscription-forbidden-message')
};

var selectors = {
    couponForm: '.js-promo-code-form',
    submitCodeBtn: '.js-promo-code-btn',
    promoCodeInput: '.js-promo-code-input',
    removeCouponBtn: '.js-remove-coupon',
    removeProductBtn: '.js-remove-product',
    quantitySelect: '.js-quantity-form > .js-quantity-select',
    shippingDiscounts: '.js-shipping-discounts',
    approachingDiscounts: '.js-approaching-discounts',
    removedProduct: '.js-removed-product',
    cartError: '.js-cart-error'
};

var classes = {
    displayNone: 'display-none',
    isInvalid: 'is-invalid',
    disabled: 'disabled'
};

/**
 * Renders empty cart
 * @param {Object} data - Product data
 */
function renderEmptyCart(data) {
    clearErrorNotification();

    if ('emptyCartHtml' in data) {
        elements.$cartPage.addClass('cart-empty').html(data.emptyCartHtml);
    }
}

/**
 * Creates error on country update, if some products are out of stock and removed from the cart
 * @returns {void}
 */
function onCountryUpdate() {
    var cartErrorMsg = elements.$errorContainer.data('cart-error');

    var removedProducts = $('.js-cart-page').data('removed-products');
    if (removedProducts) {
        var errorMsg = `${cartErrorMsg} <span class="bold">${removedProducts}</span>`;
        createErrorNotification(errorMsg, selectors.cartError);
    }
}

/**
 * Updates minicart information
 * @param {Object} data - AJAX response from the server
 */
function updateMiniCart(data) {
    elements.$miniCartQuantity.html(data.numItems);
    elements.$miniCartLink.attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
}

/**
 * Updates number of items information
 * @param {Object} data - AJAX response from the server
 */
function updateItemsNumber(data) {
    elements.$numberOfItems.html(data.resources.numberOfItems);
}

/**
 * Updates coupons and promos
 * @param {Object} data - AJAX response
 */
function updateCouponsAndPromos(data) {
    elements.$couponsAndPromos.html(data.totals.discountsHtml);

    var action = elements.$couponsAndPromos.data('action');
    elements.$couponsAndPromos.find(selectors.removeCouponBtn).attr('data-action', action);
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            createErrorNotification(data.valid.message);
        } else {
            renderEmptyCart(data);
            updateItemsNumber(data);
            updateMiniCart(data);
        }

        elements.$checkoutBtn.addClass(classes.disabled);
    } else {
        elements.$checkoutBtn.removeClass(classes.disabled);
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    const $taxTotal = $('.js-tax-total');
    const $subtotal = $('.js-sub-total');
    const $saleTotal = $('.js-sale-total');
    const $saleLabelRow = $('.js-sale-wrapper');


    const freeLabelText = $saleLabelRow.data('label-free');

    updateItemsNumber(data);
    $taxTotal.html(data.totals.totalTax);
    $('.js-grand-total').empty().append(data.totals.grandTotalNoShipping);
    $subtotal.html(data.totals.subTotal);

    if (data.totals.saleFormatted === freeLabelText) {
        $saleLabelRow.addClass('display-none');
    } else {
        $saleTotal.html(data.totals.saleFormatted);
    }

    updateMiniCart(data);

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            updateCouponsAndPromos(data);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).html(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').html(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').html(item.renderedPrice);
        $('.item-total-' + item.UUID).html(item.priceTotal.renderedPrice);
    });

    updateShippingFee(data);
    updateShippingDiscounts(data.totals.shippingDiscounts);
    updateApproachingDiscounts(data.approachingDiscounts);
    updateSubscriptionData(data);
    updateBonusProducts(data);
    updateOrderTotalDiscount(data);
}

/**
 * Shows/hides +Shipping fee label considering shipping cost
 *
 * @param {Object} data - basket model
 */
function updateShippingFee(data) {
    const { $grandTotal } = elements;

    if (data.totals.hasShippingFee) {
        const shippingFeeLabel = $grandTotal.data('addition');
        const shippingFeeHtml = `<span class="grand-total__addition">${shippingFeeLabel}</span>`;

        $grandTotal.append(shippingFeeHtml);
    }
}

/**
 * Updates cart on subscription selection
 * @param {Object} data - ajax response from subscription selection
 */
function updateSubscriptionData(data) {
    if (!data || data.length === 0) {
        return;
    }

    if (!data.subscription) {
        return;
    }

    var isSubscription = data.subscription.selected;
    var $appliedCoupons = $(selectors.removeCouponBtn);
    var subscriptionMode = isSubscription ? 'subscription' : 'onetime';

    elements.$cartPage.attr('data-subscription-type', subscriptionMode);

    if (isSubscription && $appliedCoupons.length > 0) {
        $.each($appliedCoupons, function(index, coupon) {
            var $coupon = $(coupon);
            var couponData = {
                url: $coupon.data('action'),
                uuid: $coupon.data('uuid'),
                couponCode: $coupon.data('code')
            };
            removePromoCode(couponData);
        });
    }

    if (data.subscription.eligible) {
        elements.$cartPage.removeClass('subscription-disable');
        elements.$subscriptionForbiddenMessage.addClass('display-none');
        elements.$subscriptionForbiddenMessage.text('');
        elements.$subscriptionDetails.removeClass('display-none');
    } else {
        elements.$cartPage.addClass('subscription-disable');
        elements.$subscriptionForbiddenMessage.html(data.subscription.forbiddenMessage);
        elements.$subscriptionForbiddenMessage.removeClass('display-none');
        elements.$subscriptionDetails.addClass('display-none');
    }

    updateSubscriptionState(subscriptionMode);
}

/**
 * Updates shipping discount messages
 * @param {Array<Object>} shippingDiscounts - new shipping discounts
 */
function updateShippingDiscounts(shippingDiscounts) {
    var $shippingDiscounts = $('.js-shipping-discounts');

    var renderedShippingDiscounts = shippingDiscounts.map((discount) => {
        return `<p class="single-shipping-discount shipping-discounts__item discount-item">${discount.callOutMsg}</p>`;
    });

    $shippingDiscounts.html(renderedShippingDiscounts.join());
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var $approachingDiscounts = $(selectors.approachingDiscounts);

    // eslint-disable-next-line array-callback-return
    var renderApproachingDiscounts = approachingDiscounts.map((discount) => {
        return `<p class="single-approaching-discount approaching-discounts__item discount__item">${discount.discountMsg}</p>`;
    });

    $approachingDiscounts.html(renderApproachingDiscounts.join(''));
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += `<p class="line-item-attributes">${message}</p>`;
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += `<p class="line-item-attributes line-item-instock-date">${lineItem.availability.inStockDate}</p>`;
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Show/hide coupon code submit button on input change
*/
function toggleCouponCodeSubmitButton() {
    elements.$document.on('input', selectors.promoCodeInput, function() {
        var $this = $(this);
        var $promoForm = $this.parents(selectors.couponForm);
        var $submitBtn = $promoForm.find(selectors.submitCodeBtn);

        if ($this.hasClass(classes.isInvalid)) {
            $this.removeClass(classes.isInvalid);

            elements.$couponMissingErr.hide();
            elements.$couponErrMsg.empty();
        }

        $submitBtn.removeClass(classes.displayNone);

        if (!$this.val().length) {
            $submitBtn.addClass(classes.displayNone);
        }
    });
}

/**
 * Creates success message when product is removed from the cart
 * @param {string} response - ajax response from clicking the remove product button
 */
function handlePostRemoveProduct(response) {
    var messageHtml = `<div class="message-container message-container--green message-container--alert js-removed-product">
            <p>
                <span class="bold">${response.productName}</span>
                <span> ${response.successMsg}</span>
            </p>
        </div>`;

    var $removedProductMsg = $(selectors.removedProduct);

    if ($removedProductMsg.length > 0) {
        $removedProductMsg.remove();
    }

    elements.$body.prepend(messageHtml);

    setTimeout(function () {
        $(selectors.removedProduct).remove();
    }, 2000);
}

/**
 * Saves cart page url to local storage
 */
function saveUrlToLocalStorage() {
    var isCartPage = $('.page').data('action') === 'Cart-Show';

    if (isCartPage) {
        var currentLocation = location.href;

        localStorage.setItem('cartPageUrl', currentLocation);
    }
}

/**
 * Updates total order discount
 *
 * @param {Object} basketModel - current basket model
 */
function updateOrderTotalDiscount(basketModel) {
    if (basketModel.totals.orderLevelDiscountTotal.value > 0) {
        elements.$orderDiscount.removeClass(classes.displayNone);
        elements.$orderDiscountTotal.html('- ' + basketModel.totals.orderLevelDiscountTotal.formatted);
        return;
    }

    elements.$orderDiscount.addClass(classes.displayNone);
}

/**
 * Handles promo code removal
 * @param {Object} couponData - applied coupon information
 * @param {string} url - end point to handle remove of promo code (Cart-RemoveCouponLineItem)
 * @param {string} uuid - promo code id
 * @param {string} couponeCode - coupon code
 */
function removePromoCode(couponData) {
    var url = couponData.url;
    var uuid = couponData.uuid;
    var couponCode = couponData.couponCode;

    var urlParams = {
        code: couponCode,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);

    $.spinner().start();
    elements.$body.trigger('promotion:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            $('.coupon-uuid-' + uuid).remove();

            updateCartTotals(data);
            validateBasket(data);
            $.spinner().stop();
            elements.$body.trigger('promotion:success', data);
        },
        error: function (err) {
            elements.$body.trigger('promotion:error', err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

module.exports = function () {
    elements.$body.on('click', selectors.removeProductBtn, function (e) {
        e.preventDefault();

        var $this = $(this);
        var productID = $this.data('pid');
        var url = $this.data('action');
        var uuid = $this.data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();

        elements.$body.trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    renderEmptyCart(data);
                    handlePostRemoveProduct(data);
                    updateItemsNumber(data.basket);
                    updateMiniCart(data.basket);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }

                    $('.uuid-' + uuid).remove();

                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    handlePostRemoveProduct(data);
                    updateCouponsAndPromos(data.basket);
                    updateCartTotals(data.basket);
                    elements.$body.trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                elements.$body.trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    elements.$document.on('change', selectors.quantitySelect, function () {
        var $this = $(this);
        var preSelectQty = $this.data('pre-select-qty');
        var quantity = $this.val();
        var productID = $this.data('pid');
        var url = $this.data('action');
        var uuid = $this.data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $this.parents('.product-item').spinner().start();

        elements.$body.trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                updateCouponsAndPromos(data);
                updateCartTotals(data);
                updateAvailability(data, uuid);
                validateBasket(data);
                $this.data('pre-select-qty', quantity);

                elements.$body.trigger('cart:update', data);

                $.spinner().stop();

                if ($this.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    window.location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $this.val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    elements.$document.on('submit', selectors.couponForm, function (e) {
        e.preventDefault();
        $.spinner().start();

        var $this = $(this);
        var $couponCodeInput = $this.find(selectors.promoCodeInput);

        elements.$couponMissingErr.hide();
        elements.$couponErrMsg.empty();

        if (!$couponCodeInput.val()) {
            $couponCodeInput.addClass(classes.isInvalid);
            $couponCodeInput.attr('aria-describedby', 'missingCouponCode');
            elements.$couponMissingErr.show();
            $.spinner().stop();
            return false;
        }

        $couponCodeInput.removeClass(classes.isInvalid);
        elements.$couponErrMsg.empty();

        elements.$body.trigger('promotion:beforeUpdate');

        $.ajax({
            url: $this.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $this.serialize(),
            success: function (data) {
                if (data.error) {
                    $couponCodeInput.addClass(classes.isInvalid);
                    $couponCodeInput.attr('aria-describedby', 'invalidCouponCode');
                    elements.$couponErrMsg.html(data.errorMessage);

                    elements.$body.trigger('promotion:error', data);
                } else {
                    updateCouponsAndPromos(data);
                    updateCartTotals(data);
                    validateBasket(data);

                    elements.$body.trigger('promotion:success', data);
                }

                $couponCodeInput.val('');
                var $submitBtn = $this.find(selectors.submitCodeBtn);
                $submitBtn.addClass(classes.displayNone);

                $.spinner().stop();
            },
            error: function (err) {
                elements.$body.trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    elements.$document.on('click', selectors.removeCouponBtn, function(e) {
        e.preventDefault();
        var $this = $(this);
        var couponData = {
            url: $this.data('action'),
            uuid: $this.data('uuid'),
            couponCode: $this.data('code')
        };
        removePromoCode(couponData);
    });

    elements.$body.on('cart:updated', (event, data) => {
        updateCartTotals(data);
    });

    onCountryUpdate();
    toggleCouponCodeSubmitButton();
    saveUrlToLocalStorage();
};
