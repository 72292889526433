'use strict';

const elements = {
    $giftsIncludedWrapped: $('.js-gifts-included-msg-wrapper')
};

/**
 * Function that updates the bonus product status on Cart page
 * @param {Object} data - AJAX response from the server
 */
function updateBonusProducts(data) {
    const items = data.items;

    elements.$giftsIncludedWrapped.empty();
    removeBonusProducts(items);

    if (data.hasBonusProduct) {
        addBonusProducts(items);
        elements.$giftsIncludedWrapped.append(addBonusProductsMessage(items));
    }
}

/**
 * Function that returns a template of Free Bonus Products message
 * @param {Object} items - Items (Products)
 * @returns {string} template to render
*/
function addBonusProductsMessage(items) {
    let result = '';
    let giftsIncludedLabel = elements.$giftsIncludedWrapped.data('gifts');
    let bonusProductsNames = items
        .filter(product => product.isBonusProductLineItem)
        .map(product => product.productName);
    result += `
        <div class="message-container message-container--grey js-bonus-products-notification">
            <p>
                ${giftsIncludedLabel}
            </p>
            <div>${bonusProductsNames.map(productName => renderBonusProductName(productName)).join('')}</div>
        </div>
    `;
    return result;
}

/**
 * Function that returns product name for each bonus product
 * @param {string} productName - Product Name
 * @returns {string} template to render
*/
function renderBonusProductName(productName) {
    return `<p class="bold">${productName}</p>`;
}

/**
 * Function for removing bonus products on met condition from Cart page
 * @param {Array} items - items array from data response of AJAX call
 */
function removeBonusProducts(items) {
    const $pageBonusProducts = $('.js-bonus-product');
    const existingUUIDs = items.map((item) => item.UUID);
    $.each($pageBonusProducts, (i, element) => {
        const $element = $(element);
        const uuid = $element.data('uuid');
        if (!existingUUIDs.includes(uuid)) {
            $element.remove();
        }
    });
}

/**
 * Function for adding bonus products on met condition to Cart page
 * @param {Object} items - items array from data response of AJAX call
*/
function addBonusProducts(items) {
    const products = {};
    let activeMasterProduct;
    items.forEach((item) => {
        if (item.isBonusProductLineItem) {
            products[activeMasterProduct].bonusProducts.push(item);
        } else {
            products[item.UUID] = {
                bonusProducts: []
            };
            activeMasterProduct = item.UUID;
        }
    });
    Object.keys(products).forEach((masterProductID) => {
        const $pageProduct = $(`.uuid-${masterProductID}`);
        const bonusProducts = products[masterProductID].bonusProducts;
        $pageProduct.after(getBonusProductsRendered(bonusProducts));
    });
}

/**
 * Function that returns template for bonus products to be rendered
 * @param {Array} bonusProducts - Array of available bonus products
 * @returns {string} template to render
*/
function getBonusProductsRendered(bonusProducts) {
    let result = '';
    let $cartPage = $('.js-cart-page');
    let price = $cartPage.data('free');
    bonusProducts.forEach((bonusProduct) => {
        const productExists = $(`.uuid-${bonusProduct.UUID}`).length;
        if (!productExists) {
            result += `
                <div data-uuid="${bonusProduct.UUID}" class="product-item card product-info uuid-${bonusProduct.UUID} js-bonus-product">
                    <div class="product-item__content">
                        <div class="product-item__image-wrapper item-image">
                            <img
                                class="product-item__image product-image"
                                src="${bonusProduct.images.small[0].url}"
                                alt="${bonusProduct.images.small[0].alt}"
                                title="${bonusProduct.images.small[0].title}"
                            />
                        </div>
                        <div class="product-item__info item-attribute">
                            <div class="product-item__header line-item-header">
                                <div class="product-item__header-info">
                                    <p class="product-item__title line-item-name">
                                        ${bonusProduct.productName}
                                    </p>
                                    <span class="product-item__detail">${price}</span>
                                </div>
                                <div class="product-item__body product-card-footer product-item__body--bonus">
                                    <span class="product-item__total"> ${bonusProduct.quantity} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        }
    });
    return result;
}

module.exports = {
    updateBonusProducts
};
