'use strict';

var cookieHelper = require('../utils/cookieHelper');

var $ = require('jquery');

const COOKIE_NAME = 'tracking_consent_preferences';
const COOKIE_OPTIONS = ['marketing', 'personalization', 'performance'];
const COOKIE_DIALOG_WRAPPER = 'cookie-dialog-wrapper';

const elements = {
    body: 'body',
    openConsentWindow: '.js-open-consent-window',
    consentDialogueContent: '.js-cookie-consent-dialog-content',
    cookieDialogScreen: '.js-cookie-dialog-screen',
    closeCookieModal: '.js-close-cookie-bnt',
    cookieMarketing: '#cookie-select-marketing',
    cookiePersonalization: '#cookie-select-personalization',
    cookiePerformance: '#cookie-select-performance',
    cookieAll: '#cookie-select-all'
};

const classes = {
    displayNone: 'display-none',
    backdropMask: 'backdrop-mask',
    stopScrolling: 'stop-scrolling'
};

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 * @param {boolean} onlyModalForm Decides what is shown
*/
function showConsentModal(onlyModalForm = false) {
    const {
        body, consentDialogueContent, cookieDialogScreen, closeCookieModal
    } = elements;

    const { displayNone, backdropMask, stopScrolling } = classes;

    if (!$('#cookie-consent').data('caonline')) {
        return;
    }

    var urlContent = $('#cookie-consent').data('url');

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $(body).append(`<div id="${COOKIE_DIALOG_WRAPPER}">${response}</div>`);
            $(body).addClass(`${stopScrolling} ${backdropMask}`);
            if (onlyModalForm) {
                $(consentDialogueContent).removeClass(displayNone);
                loadCookiePreferencesInForm();
                $(document).on('click', closeCookieModal, () => {
                    $(`#${COOKIE_DIALOG_WRAPPER}`).remove();
                });
            } else {
                $(cookieDialogScreen).removeClass(displayNone);
            }
        },
        error: function () {
        }
    }).done(function () {
        $(document).off('click', '.cookie-consent-accept');
        $(document).on('click', '.cookie-consent-accept', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var $cookieForm = $('.cookie-consent-form');
            var cookieConsentData = $('.cookie-dialog-modal').data('ga-view');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: getFormData($cookieForm),
                success: function (response) {
                    $(cookieDialogScreen).addClass(displayNone);
                    $(consentDialogueContent).addClass(displayNone);
                    $(body).removeClass(`${stopScrolling} ${backdropMask}`);
                    $(`#${COOKIE_DIALOG_WRAPPER}`).remove();
                    let status = $cookieForm.find('input[name="cookie_select_marketing"]').is(':checked');
                    cookieConsentData.analytics = status;
                    $(document).trigger('cookieConsent:updateCookieConsent', {
                        cookieConsentData
                    });
                },
                error: function () {
                    $(cookieDialogScreen).addClass(displayNone);
                }
            });
        });
    });
}

/**
 * Populate Cookie Preferences Form with current choice
 */
function loadCookiePreferencesInForm() {
    const { cookieAll } = elements;

    let cookiePreference = cookieHelper.getCookie(COOKIE_NAME).split('|');

    if (cookiePreference.length === 1) {
        switch (cookiePreference[0]) {
            case 'necessary':
                $(cookieAll).prop('checked', false);
                COOKIE_OPTIONS.forEach((option) => {
                    $(`#cookie-select-${option}`).prop('checked', false);
                });
                break;
            case 'all':
            default:
                break;
        }
    } else {
        $(cookieAll).prop('checked', false);
        COOKIE_OPTIONS.forEach((item) => {
            $(`#cookie-select-${item}`).prop('checked', cookiePreference.includes(item));
        });
    }
}

/**
 * Getting data from the form
 * @param {Object} $form values
 * @returns {indexedArray} with strings
 */
function getFormData($form) {
    var unindexedArray = $form.serializeArray();
    var indexedArray = {};

    $.map(unindexedArray, function (n) {
        indexedArray[n['name']] = n['value'];
    });

    return indexedArray;
}

module.exports = function () {
    if (!$('#cookie-consent').data('consented')) {
        showConsentModal();
    }

    $('.js-footer-edit-cookies').on('click', (e) => {
        e.preventDefault();
        showConsentModal(true);
    });
};
