const elements = {
    $body: document.body,
    $mainUsp: document.querySelector('.js-main-usp-bar'),
    $mainHeader: document.querySelector('.js-main-header'),
    $navToggler: document.querySelector('.js-nav-toggler'),
    $navMenu: document.querySelector('.js-navmenu-container'),
    $dropdownLinks: document.querySelector('.js-dropmenu-links-container'),
    $navMenuLink: document.querySelectorAll('.js-header-link-item'),
    $homeLogo: document.querySelector('.js-logo-home'),
    $searchList: document.querySelector('.js-search'),
    $searchWrapper: document.querySelector('.js-search-wrapper'),
    $searchBtn: document.querySelector('.js-search-icon-btn'),
    $resetSearch: document.querySelector('.js-reset-search-btn'),
    $cartList: document.querySelector('.js-cart'),
    $returnBtn: document.querySelector('.js-return-btn'),
};

const classes = {
    isOpen: 'is-open',
    isActive: 'is-active',
    remove: 'remove',
    removeTop: 'remove-top',
    scrollUp: 'scroll-up',
    borderTop: 'border-on-top',
    scrollDown: 'scroll-down',
    scrollAnimate: 'scroll-animate',
    stopScrollAll: 'stop-scrolling',
    stopScrollMobile: 'stop-scrolling-on-touch',
    stopScrollingY: 'stop-scrolling-y'
};

let lastScroll = 0;
let activeLink;

/**
 * Handles open/close state of hamburger menu, and flyout menu
 */
function togglerOpenHandler() {
    const {
        isOpen, isActive, borderTop, stopScrollingY
    } = classes;
    const {
        $navToggler, $navMenu, $searchList, $navMenuLink, $returnBtn
    } = elements;

    $navToggler.classList.toggle(isOpen);

    $navMenuLink.forEach(link => {
        link.addEventListener('click', e => {
            if (window.innerWidth < 1200) {
                e.preventDefault();
            }
            activeLink = link.parentElement;
            activeLink.classList.add(isActive);
            if (activeLink.classList.contains(isActive)) {
                $returnBtn.classList.add(isOpen);
                $navMenu.classList.remove(borderTop);
                $navMenu.classList.add(stopScrollingY);
                $searchList.classList.remove(isOpen);
            }
            if ($returnBtn.classList.contains(isOpen)) {
                $returnBtn.addEventListener('click', () => {
                    activeLink.classList.remove(isActive);
                    $returnBtn.classList.remove(isOpen);
                    $navMenu.classList.add(borderTop);
                    $navMenu.classList.remove(stopScrollingY);
                    $searchList.classList.add(isOpen);
                });
            }
        });
    });

    if ($navToggler.classList.contains(isOpen)) {
        openMenu();
    } else {
        closeMenu();
        if (!activeLink) return;
        activeLink.classList.remove(isActive);
    }
}

/**
 * Open search component
 */
function openSearch() {
    const { isActive, stopScrollAll } = classes;
    const { $searchWrapper, $mainHeader } = elements;

    $searchWrapper.classList.add(isActive);
    $mainHeader.classList.add(stopScrollAll);
    updateSearchState(true);
}

/**
 * Close search component
 */
function closeSearch() {
    const { isActive, stopScrollAll } = classes;
    const { $searchWrapper, $mainHeader } = elements;

    $searchWrapper.classList.remove(isActive);
    $mainHeader.classList.remove(stopScrollAll);
    updateSearchState(false);
}

/**
 * Handles open menu
 */
function openMenu() {
    const {
        isOpen, remove, stopScrollMobile, borderTop
    } = classes;
    const {
        $navMenu, $searchList, $cartList, $homeLogo, $body
    } = elements;

    $navMenu.classList.add(isOpen, borderTop);
    $searchList.classList.add(isOpen);
    $cartList.classList.add(remove);
    $homeLogo.classList.add(remove);
    $body.classList.add(stopScrollMobile);
}

/**
 * Handles close menu
 */
function closeMenu() {
    const {
        isOpen, remove, stopScrollMobile, stopScrollingY
    } = classes;
    const {
        $navMenu, $searchList, $returnBtn, $cartList, $homeLogo, $body
    } = elements;

    $navMenu.classList.remove(isOpen, stopScrollingY);
    $returnBtn.classList.remove(isOpen);
    $searchList.classList.remove(isOpen);
    $cartList.classList.remove(remove);
    $homeLogo.classList.remove(remove);
    $homeLogo.classList.add(isOpen);
    $body.classList.remove(stopScrollMobile);
}

/**
 * Sticky header on scrolling
 */
function stickyHandler() {
    const {
        scrollUp, scrollDown, scrollAnimate, removeTop
    } = classes;
    const {
        $body, $mainUsp, $mainHeader, $searchWrapper
    } = elements;
    const currentScroll = window.pageYOffset;
    const uspHeight = $mainUsp.offsetHeight;
    const mainNavHeight = $mainHeader.offsetHeight;
    const headerHeight = uspHeight + mainNavHeight;

    if (currentScroll >= uspHeight / 2) {
        $searchWrapper.classList.add(removeTop);
    } else {
        $searchWrapper.classList.remove(removeTop);
    }

    if (currentScroll <= uspHeight) {
        $body.classList.remove(scrollAnimate);
        $body.classList.remove(scrollUp);
        $body.classList.remove(scrollDown);
        return;
    }

    if (currentScroll > headerHeight && currentScroll > lastScroll) {
        if (!$body.classList.contains(scrollDown)) {
            $body.classList.remove(scrollUp);
            $body.classList.add(scrollDown);
        } else {
            $body.classList.add(scrollAnimate);
        }
    } else if (currentScroll < lastScroll && $body.classList.contains(scrollDown)) {
        $body.classList.remove(scrollDown);
        $body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
}

/**
 * Tracks if search bar is open or closed.
 * If open, an event lister is added to window, to close the search bar on clicking outside of the search bar
 * @param {boolean} isOpen - tracks if search bar is open or closed
 */
function updateSearchState(isOpen) {
    const method = isOpen ? 'addEventListener' : 'removeEventListener';

    window[method]('click', function(e) {
        if (!elements.$mainHeader.contains(e.target)) {
            closeSearch();
        }
    });
}

/**
 * Set scrollRestoration to 'manual' if you want to return to top
 */
history.scrollRestoration = 'auto';

/**
 * Handles all event listeners
 */
function handleEventListeners() {
    const {
        $navToggler,
        $searchBtn,
        $resetSearch
    } = elements;

    $navToggler.addEventListener('click', togglerOpenHandler);
    window.addEventListener('scroll', stickyHandler);

    $searchBtn.addEventListener('click', openSearch);
    $resetSearch.addEventListener('click', closeSearch);
}

/**
 * Initialize the header
 */
function init() {
    if (elements.$mainHeader) {
        handleEventListeners();
    }
}

export default {
    init
};
