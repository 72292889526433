const selectors = {
    errorContainer: '.js-error-messaging',
    errorText: '.js-alert-text',
    closeErrorBtn: '.js-close-alert',
    alert: '.js-alert'
};

const classes = {
    displayNone: 'display-none',
    alertSuccess: 'alert-success',
    alertDanger: 'alert-danger'
};

const elements = {
    $document: $(document),
    $body: $('body')
};

/**
 * Create an alert to display the error message
 * @param {string} message - Error message to display
 * @param {string} errorElSelector - Selector for the element that will be used to display error message (default value is '.error-messaging')
 */
function createErrorNotification(message, errorElSelector = selectors.errorContainer) {
    const { errorText, alert } = selectors;
    const { displayNone, alertSuccess, alertDanger } = classes;
    const $errorEl = $(errorElSelector);
    const $alertEl = $(alert);

    if (!message || message.length === 0) {
        clearErrorNotification(errorElSelector);
        return;
    }

    $errorEl.removeClass(displayNone);
    $errorEl.find(errorText).html(message);

    let isSuccess = $errorEl.find(alertSuccess);

    if (isSuccess) {
        $alertEl.removeClass(alertSuccess).addClass(alertDanger);
    }

    handleEventListener();
}

/**
 * Clears the error message
 * @param {jQuery|string|undefined} $errorContainer - jQuery element - error message container (error-messaging .js-error-messaging)
 */
function clearErrorNotification($errorContainer) {
    let errorContainerEl = null;
    const { errorContainer } = selectors;
    const { $body } = elements;
    const { displayNone } = classes;

    if ($errorContainer) {
        if (typeof $errorContainer === 'string' && $errorContainer.length > 0) {
            errorContainerEl = $($errorContainer);
        } else {
            errorContainerEl = $errorContainer;
        }
    } else {
        errorContainerEl = $(errorContainer);
    }

    if (errorContainerEl && typeof errorContainerEl === 'object' && errorContainerEl.length > 0) {
        $body.trigger('error:beforeClear');
        errorContainerEl.addClass(displayNone);
    }

    $body.trigger('error:afterClear');
}

/**
 * Adds event listener on close error message button
 */
function handleEventListener() {
    const { errorContainer, closeErrorBtn } = selectors;
    const { $document } = elements;

    $document.on('click', closeErrorBtn, function() {
        const $this = $(this);
        const $errorContainer = $this.closest(errorContainer) || $this.closest('.error-messaging');

        if ($errorContainer.length !== 0) {
            clearErrorNotification($errorContainer);
        }
    });
}

module.exports = {
    init: function() {
        handleEventListener();
    },
    createErrorNotification,
    clearErrorNotification
};
