'use strict';

const cart = require('../cart/cart');

const elements = {
    $miniCart: $('.minicart'),
    $body: $('body'),
    $miniCartQuantity: $('.minicart-quantity'),
    $miniCartLink: $('.minicart-link')
};

let updateMiniCart = true;

module.exports = function () {
    const {
        $miniCart, $body, $miniCartQuantity, $miniCartLink
    } = elements;

    cart();

    $miniCart.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $miniCartQuantity.text(count.quantityTotal);
            $miniCartLink.attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $body.on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $body.on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    $body.on('cart:update', function () {
        updateMiniCart = true;
    });
};
