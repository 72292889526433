const elements = {
    $win: $(window),
    $body: $('body')
};

const classes = {
    disabledScroll: 'scroll-disabled'
};

window.currentScrollTop = 0;

const scroll = {
    // Disable window scroll when popups, navigation and similar are opened
    disableScroll: function () {
        if (!window.disableScroll) {
            window.currentScrollTop = elements.$win.scrollTop();
            elements.$body.css('top', -window.currentScrollTop + 'px').addClass(classes.disabledScroll);
            window.disableScroll = true;
        }
    },

    // Enable back window scroll when closing the opened overlays
    enableScroll: function () {
        elements.$body.removeAttr('style').removeClass(classes.disabledScroll);
        elements.$win.scrollTop(window.currentScrollTop);
        window.disableScroll = false;
    }
};

export { scroll };
