/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.includes('?') ? '&' : '?')
        + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

export {
    appendToUrl
};
