const scroll = require('../utils/scroll').scroll;

const elements = {
    $body: $('body'),
    $modals: $('.js-modal'),
    $modalBg: $('.js-modal-bg'),
    $document: $(document)
};

const classes = {
    displayNone: 'display-none',
    modalOpen: 'modal-open'
};

/**
 * Handles the custom events
 * @param {event} event - click event
 * @param {string} id - modal id
 */
function handleEvents(event, id) {
    const handler = $(event.target).data('modal');

    if (handler === 'openModal') {
        openModal(id);
    } else {
        closeModal(id);
    }
}

/**
 * Open the modal based on it's id, on click or from ajax call
 * @param {string} id - modal id
 */
function openModal(id) {
    const { displayNone, modalOpen } = classes;
    const { $modalBg, $body } = elements;
    const $modalSelector = $(id);

    $modalSelector.trigger('modal:beforeOpen');

    $modalSelector.removeClass(displayNone);

    if ($modalBg.length !== 0) {
        $modalBg.removeClass(displayNone);
    }

    $body.addClass(modalOpen);
    scroll.disableScroll();
    $modalSelector.trigger('modal:afterOpen');
}

/**
 * Close the modal based on it's id, on click or from ajax call
 * @param {string} id - modal id
 */
function closeModal(id) {
    const { displayNone, modalOpen } = classes;
    const { $modalBg, $body } = elements;
    const $modalSelector = $(id);

    $modalSelector.trigger('modal:beforeClose');
    $modalSelector.addClass(displayNone);

    if ($modalBg.length !== 0) {
        $modalBg.addClass(displayNone);
    }

    $body.removeClass(modalOpen);
    scroll.enableScroll();
    $modalSelector.trigger('modal:afterClose');
}

/**
 * init function
 */
function initEvents() {
    const { $modals } = elements;

    if ($modals.length === 0) {
        return;
    }

    const target = $('[data-modal]');

    if (target.length === 0) {
        return;
    }

    $(document).on('click', '[data-modal]', function(e) {
        e.stopPropagation();

        if (e.target === this) {
            const id = $(e.target).data('target');
            handleEvents(e, id);
        }
    });
}

module.exports = {
    init: function() {
        initEvents();

        elements.$document.on('modal:closeModal', function(e, modalSelector) {
            closeModal(modalSelector);
        });

        elements.$document.on('modal:openModal', function(e, modalSelector) {
            openModal(modalSelector);
        });
    },
    openModal: openModal,
    closeModal: closeModal
};
