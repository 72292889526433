const elements = {
    quantityBtnEl: document.querySelectorAll('.js-quantity-btn')
};

const selectors = {
    selectOverlay: '.js-select-overlay'
};

let currentIndex;

/**
 * Handles all event listeners
 * Adding the event listener to buttons to increase/decrease quantity
 */
function handleEventListeners() {
    const { quantityBtnEl } = elements;

    quantityBtnEl.forEach(btn => {
        let isIncrease = btn.dataset.action === 'ADD';
        let select = `#${btn.dataset.targetId}`;

        btn.addEventListener('click', () => isIncrease ? increaseValue(select) : decreaseValue(select));
    });
}

/**
 * Handles quantity increase
 * @param {string} select - select element id or class
 */
function increaseValue(select) {
    const selectEl = document.querySelector(select);
    const maxValue = parseInt(selectEl.dataset.max, 10);
    const selectOverlay = selectEl.parentElement.querySelector('.select-overlay-btn');

    currentIndex = parseInt(selectEl.value, 10);

    currentIndex++;

    if (currentIndex > maxValue) {
        currentIndex = maxValue;
        return;
    }

    selectEl.value++;

    $(select).trigger('change');
    selectOverlay.innerHTML = currentIndex;
}

/**
 * Handles quantity decrease
 * @param {string} select - select element id or class
 */
function decreaseValue(select) {
    const selectEl = document.querySelector(select);
    const minValue = parseInt(selectEl.dataset.min, 10);
    const selectOverlay = selectEl.parentElement.querySelector('.select-overlay-btn');

    currentIndex = parseInt(selectEl.value, 10);

    currentIndex--;

    if (currentIndex < minValue) {
        currentIndex = minValue;
        return;
    }

    selectEl.value = currentIndex;

    $(select).trigger('change');
    selectOverlay.innerHTML = currentIndex;
}

/**
 * Initialize the function
 */
function init() {
    handleEventListeners();
}

export default {
    init
};
